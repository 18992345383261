import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesSat: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Claves del SAT
            </h2>
            <h3>
                Configuración individual
            </h3>
            <p>
                Recordando brevemente, al configurar tu tienda en FiscalPOP es necesario agregar claves Predeterminadas de producto y servicio en "Mi configuración". <br />
                <b>Si te saltaste ese capítulo, revisalo entrando <Link to="../claves-default">aquí</Link> antes de continuar.</b>
            </p>
            <div className="row two">
                <ImageFooted explanation={`La pantalla de editar claves de un producto es homogénea en todas las secciones.`}>
                    <IMG_Shopify file={'productSAT'} />
                </ImageFooted>
                <div>
                    <ol>
                        <li>
                            <p>
                                <b>Puedes buscar las claves usando texto</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Claves de producto y unidad funcionan igual</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b><span className="accent">Es necesario dar click a la opción</span></b>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Si el recuadro continúa en rojo, es que no se seleccionó la opción, y la clave puede ser inválida</b>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Cuando termines, solo da click en guardar</b>
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
            <h3>
                Configuración múltiple
            </h3>
            <p>
                Para editar varios productos a la vez, entra a la opción de <b>Edición Múltiple</b> desde "Mis productos".
            </p>
            <ImageFooted explanation={`La edición múltiple te permite seleccionar toda una categoría de productos a la vez, o un subset de estos, editándolos igual que un producto individual.`}>
                <IMG_Shopify file={'productMultipleSAT'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/configurar-impuestos`} next={`/ecommerce/shopify/claves-pedimentos`}/>
        </div>
    </Layout>
)

export default ShopifyClavesSat;